.container-background {
  background-color: #0d77e0;
}

.full-container {

  width: 90%;
  margin: 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

}

.search-delete {
  background-color: #F7F8F9;
  margin: 10px;
}

.border {
  background-color: white;
}

.label-row {
  color: #344054;

}
.table-boder {
  border: none;
}
.manage-heading {
  color:  #64748B;
  
}
.btn-settings, .btn-add {
  background-color: #005175;
  color: white;
  border: none;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  font-size: Manrope;
  gap: 5px;
}
.statusbutton {
  
}
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.lesson-details {
  display: flex;
  gap: 20px;
}

.lesson-image img {
  width: 150px;
  height: 150px;
  object-fit: cover;
}

.tutorials {
  display: flex;
  gap: 10px;
}

.tutorial-card {
  width: 150px;
  text-align: center;
}
