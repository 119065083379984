.container-background{
    
}
.lesson-details-container {
    padding: 20px;
    max-width: 900px;
    margin: auto;
    border: 1px solid #D0D5DD;
    border-radius: 8px;
  }
  
  .header {
    display: flex;
    margin-bottom: 20px;
  }
  
  .back-button {
    font-size: 20px;
    margin-right: 20px;
  }
  
  .lesson-content {
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .lesson-info {
    display: flex;
    margin-bottom: 30px;
  }
  
  .lesson-image img {
    width: 200px;
    height: auto;
    border-radius: 8px;
    margin-right: 20px;
  }
  
  .lesson-description {
    flex: 1;
  }
  
  .lesson-description h3 {
    margin: 0;
  }
  
  .level {
    color: red;
    font-weight: bold;
    margin-bottom: 10px;
    display: block;
  }
  
  .tutorials-section {
    margin-bottom: 20px;
  }
  
  .tutorials {
    display: flex;
    gap: 20px;
  }
  
  .tutorial-card {
    width: 200px;
    text-align: center;
  }
  
  .tutorial-card img {
    width: 100%;
    border-radius: 8px;
  }
  
  .actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
  