/* Container */
.add-lesson-container {
    width: 600px;
    margin: 20px auto;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Progress bar */
  .progress-bar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .step {
    font-size: 16px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
  }
  
  .step.completed {
    color: green;
  }
  
  .step.completed span {
    font-size: 20px;
    margin-right: 8px;
  }
  
  .step.pending {
    color: grey;
  }
  
  .step.pending span {
    font-size: 18px;
    margin-right: 8px;
  }
  
  /* Form styling */
  .form-container {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 14px;
  }
  
  textarea {
    height: 100px;
    resize: none;
  }
  
  /* Thumbnail Upload */
  .thumbnail-upload  {
    display: flex;
    align-items: center;
  }
  
  .media-upload {
    display: flex;
    align-items: center;
    border: 2px dashed #ccc;
    border-radius: 5px;
    padding: 10px;
  }
  
  .upload-btn {
    background-color: #F5F6FA;
    color: #005175;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .upload-input {
    display: none;
  }
  
  .uploaded-file {
    margin-left: 15px;
    display: flex;
    align-items: center;
    border: solid 2px;
    border-radius: 5px;
    background: #E8F0FD;
    padding: 4px;
    

  }
  
  .uploaded-file button {
    background: none;
    color: red;
    border: none;
    margin-left: 10px;
    cursor: pointer;
    padding: 5px 8px;
    border-radius: 5px;
  }
  
  /* Media Upload */
  .media-upload {
    position: relative;
  }
  
  .media-upload label {
    font-size: 20px;
    color: white;
    background: #005175;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 5px;
    padding: 6px;
    
  }
  
  
  .media-upload p {
    margin-top: 10px;
    color: #666;
    font-size: 14px;
  }
  
  .uploaded-file {
    margin-left: 15px;
  }
  
  /* Form buttons */
  .form-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .cancel-btn {
    background-color: #e74c3c;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .submit-btn {
    background-color: #2ecc71;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  