.add-lesson-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .add-lesson-container h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  .header {
    display: flex;
    margin-bottom: 20px;
  }
  .back-button {
    font-size: 20px;
    margin-right: 20px;
  }
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
  }
  
  .form-group input,
  .form-group select,
  .form-group textarea {
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .form-actions {
    display: flex;
    justify-content: end;
  }
  
  .cancel-button,
  .add-button {
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .cancel-button {
    background-color: white;
   border: 1px solid #D0D5DD;
    margin-right:5px ;
  }
  
  .add-button {
    background-color: #005175;
    color: white;
  }
    
  