.mainContainer {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;
}

.left {
    width: 70%;
    height: 100vh;
    overflow-y: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.right {
    width: 30%;
    height: 100vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 3%;
    border-left: 1px solid gray;
}

.centerContent{
    width: 100%;
    max-width: 500px;
}

/* .login_btn{
    background: black;
    color: white;
}

.login_btn:hover{
    background: rgb(10, 10, 10) !important;
    color: white !important;
} */

.password_container{
    position: relative;
}

.icon{
    position: absolute;
    top: 2px;
    right: 18px;
    cursor: pointer;
}


@media screen and (max-width:1000px) {
    .left {
        width: 50%;
    }
    
    .right {
        width: 50%;
    }
}
@media screen and (max-width:600px) {
    .left {
       display: none;
    }
    
    .right {
        width: 100%;
        padding: 0 4%;
    }
}